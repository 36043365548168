body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: transparent;  
  font-family: 'Outfit'
}

h1 {
  font-size: 90px;
  text-transform: uppercase;
  text-align: center;
  margin-top: 20rem;
  font-family: "Outfit";
}

h2 {
  font-size: 70px;
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Outfit";
}

h3 {
  font-weight: 600;
  font-size: 28px;
  font-family: "Outfit";
}

h6 {
  font-family: "Inter, sans-serif";
}
