header {
    &.footer-wrapper {
        background-color: #0E141E;
        padding-top: 60px;

        .container {
            width: 1110px;
            display: flex;
            flex-direction: column;
            margin: 0px auto;

            .f-top-menu {
                display: flex;
                flex-direction: row;
                align-items: center;
                border-bottom: 1px solid #273040;
                padding-bottom: 40px;

                img{
                    cursor: pointer;
                }

                ul {
                    display: flex;
                    flex-direction: row;
                    gap: 50px;
                    margin-left: 60px;
                    li {
                        gap: 20px;
                        display: flex;
                        .f-nav-item {
                            font-family: "Outfit";
                            font-size: 18px;
                            color: white;

                            &:hover{
                                color: #AA8425;
                            }
                        }
                    }
                }

                .socail-link {
                    gap: 1rem;
                    display: flex;
                    margin-left: auto;
                    
                    .s-icon {
                        background-color: #5C6575;
                        width: 40px;
                        height: 40px;
                        display: flex;
                        align-items: center;justify-content: center;
                        border-radius: 50px;
                        cursor: pointer;

                        &:hover{
                            // background-color: red;
                            svg path{
                                fill: #D09707;
                            }
                        }
                    }
                }
            }

            .f-bottom-menu{
                width: 100%;
                display: flex;
                flex-direction: row;
                padding: 15px 0px 15px;
                font-family: "Outfit";

                .copyright-text{
                    color: #57606F;
                    font-family: "Outfit";
                    font-weight: 400;
                }

                .privacy-link {
                    display: flex;
                    flex-direction: row;
                    color: #57606F;
                    margin-left: auto;
                    font-weight: 400;
                    p{
                       padding: 0px;
                       font-family: "Outfit";
                       cursor: pointer;
                       
                       &.dot::before{
                        content: '';
                        position: relative;
                        display: inline-block;
                        width: 8px;
                        height: 8px;
                        background: #AA8425;
                        border-radius: 8px;
                        margin:0px 10px;
                       }
                    }
                }
            }
        }
    }
}

@media(max-width: 1110px) {
    header {
        &.footer-wrapper {
            .container {
                width: 100%;
                padding: 0px 1rem;
            }
        }
    }
}
@media(max-width: 990px) {
    header {
        &.footer-wrapper {
          .container {
            .f-top-menu {
              flex-direction: column;
    
              .socail-link {
                margin: 0px auto;
              }
    
              ul {
                margin: 2rem auto;
              }
            }
    
            .f-bottom-menu {
              display: flex;
              flex-direction: column;
              align-items: center;
    
              .privacy-link {
                margin: 1rem auto 0px;
              }
            }
          }
        }
      }
}
@media (max-width: 575px) {

    header.footer-wrapper {
        .container {
            .f-top-menu {
                ul {
                    li {
                        .f-nav-item {
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
    } 

    header {
        &.footer-wrapper {
          .container {
            .f-top-menu {
              ul {
                gap: 2rem;
              }
            }
          }
        }
      }
}
