header {    
    &.root {
    width: 100%;
    box-shadow: none;
    font-family: "Outfit";
    min-height: 81px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;

    .registration-btn{
        background: #AA8425;
        color: white;
        border-radius: 5px;
        font-size: 14px;
        text-align: center;
        border-radius: 5px;
        border: 1px solid #AA8425;
        font-family: "Outfit";
        min-width: 236px;
        padding: 5px 5px;
        margin-left: 16px;
    
        &:hover{
            background: #AA8425; 
        }
    }

    .menu-wrapper{
        display: flex;
        flex-direction: row;
        height: auto;
        width: auto;
    }
  }
}

.nav-item{
    gap: 2%;
    margin-left: 1%;
    .nav-link{
        font-family: "Outfit";

        &:hover{
            color: #AA8425;
            background-color: transparent;
        }
    }
}

.logo{
    display: flex;
    max-width: 200px;
}
.login-and-register{
    display: flex;
    flex-direction: row;
        overflow: hidden;
}

.nav-item button:last-child{
    display: none;
}


@media(max-width:990px){
    #menu-appbar{
        div{
            width: 100%;
        ul{
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 0px;
            padding-bottom: 0px;

            li{
                align-items: center;
                border-bottom: 1px solid #e2e2;
                justify-content: flex-start;
                padding-left: 1em;

                &:hover{
                    color: #AA8425;
                }
                &:focus{
                    color: #AA8425;
                }

                p{
                    font-family: "Outfit";
                }
            }
        }
    }
    }
}
@media (max-width:899px){
    header {
            &.root {
                .registration-btn {
                    display: none;
                }
            }
        }
    .MuiToolbar-regular{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .logo{
        order: 2;
    }        
    .icon-bar{
        order: 1;
        flex-grow: inherit !important;
    }
    .login-and-register{
        order: 3;
        button{
            font-size:1.2rem;
            margin-right: 0px;
            padding: 0px;
        }
    }
}