.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.header-404 {
  margin: 5rem;
}

.paragraph-404 {
  font-weight: 500;
  margin: 1.5rem;
}
